import React from "react";
import ImageGallery from "react-image-gallery";

const Gallery = ({ images, thumbs }) => {
  const items = images.map((item, i) => ({
    original: item,
    thumbnail: thumbs[i]
  }));

  return <ImageGallery items={items} showPlayButton={false} showFullscreenButton={false} />;
};

export default Gallery;
